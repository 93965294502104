import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'latestepisode',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "episode" */ '@/views/episode.vue')
  },
  {
    path: '/seasons/:seasonid/episodes/:episodeid',
    name: 'episode',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "episode" */ '@/views/episode.vue')
  },
  {
    path: '/characters',
    name: 'characters',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "episode" */ '@/views/characters.vue')
  },
  {
    path: '/metadata',
    name: 'metadata',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "episode" */ '@/views/metadata.vue')
  },
];

export const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
});
