import createPersistedState from 'vuex-persistedstate';
import { createStore } from 'vuex'
import { data_service } from './data_service';

const state = {
  seasons: [],
  season: 0,
  episode: 0,
  paragraphs: [
    "First",
    "Middle",
    "Last"
  ],
};

function getAwsValue(type, sourceHash) {
  return sourceHash[type];
}

function parseId(idStr) {
  const parts = idStr.split(':');

  return parts.length === 2 ? Number(parts[1]) : idStr;
}

const mutations = {
  set_seasons(state, seasons) {
    if (seasons.Count === 0 || seasons.Items.length === 0) {
      state.seasons = [];
    } else {
      state.seasons = seasons.Items.map(season => {
        return {
          season: parseId(getAwsValue('S', season.sort_key)),
          description: getAwsValue('S', season.description),
          episode_count: Number(getAwsValue('N', season.episode_count))
        };
      });
    }
  },

  set_current_season(state, season) {
    let season_id = Number(season);

    if (season_id > 0 && season <= state.seasons.length) state.season = season_id;
  },

  set_current_episode(state, episode) {
    if (episode.Item === undefined || episode.Item.partition_key === undefined) {
      state.season = 0;
    } else {
      state.season = parseId(getAwsValue('S', episode.Item.partition_key));
    }

    if (episode.Item === undefined || episode.Item.sort_key === undefined) {
      state.episode = 0;
    } else {
      state.episode = parseId(getAwsValue('S', episode.Item.sort_key));
    }

    if (episode.Item === undefined || episode.Item.paragraphs === undefined) {
      state.paragraphs = ['Unknown Episode!'];
    } else {
      state.paragraphs = episode.Item.paragraphs.L.map(para => getAwsValue('S', para));
    }
  }
};

const actions = {
  async show_latest(context) {
    const latest = await data_service.show_latest();

    if (latest) context.commit('set_latest',  latest);
  },

  async show_seasons(context) {
    const seasons = await data_service.show_seasons();

    if (seasons) context.commit('set_seasons',  seasons);
  },

  async show_season(context, season_id) {
    const season = await data_service.show_season(season_id);

    if (season) context.commit('set_current_season',  season);
  },

  async show_episode(context, episode_details) {
    const episode = await data_service.show_episode(episode_details.season_id, episode_details.episode_id);

    if (episode) context.commit('set_current_episode',  episode);
  },
};

const getters = {
  latest_season: state => {
    return state.latest_season;
  },
  latest_episode: state => {
    return state.latest_episode;
  },
  season: state => {
    return state.season;
  },
  episode: state => {
    return state.episode;
  },
  paragraphs: state => {
    return state.paragraphs;
  },
};

const modules = {
};

export const store = createStore({
  state,
  mutations,
  actions,
  getters,
  modules,
  plugins: [createPersistedState({
    key: 'keyname',
    storage: window.localStorage,
  })],
});
